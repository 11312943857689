<template>
  <!-- 開團預設設定 -->
  <div class="main-content">
    <div class="content-block">
      <h2 class="lg-title">
        <div class="title-word">領貨方式</div>
      </h2>
      <div class="input-tab-block">
        <div
          class="radio-block"
          v-for="(type, index) in deliveryTypes"
          :key="`${index}${type.id}`"
        >
          <input
            :id="type.id"
            type="radio"
            name="delivery"
            :value="type.value"
            v-model="settings.deliveryType"
          />
          <label :for="type.id">{{ type.text }}</label>
        </div>
      </div>
      <div
        id="taketime"
        class="hidden-block"
        :class="{ active: settings.deliveryType == 1 }"
      >
        <div class="new-item-list f2f-list">
          <div
            class="new-item"
            v-for="(shippingOption, index) in settings.shippingOptions"
            :key="index"
          >
            <div class="item-top">
              <div class="input-wrap">
                <TimePicker
                  :time="shippingOption.time_from"
                  placeholder="請選擇開始時間"
                  :data-index="index"
                  data-key="time_from"
                  @update-time="updateTime"
                ></TimePicker>
              </div>
              <div class="input-wrap">
                <TimePicker
                  :time="shippingOption.time_to"
                  :data-index="index"
                  data-key="time_to"
                  placeholder="請選擇結束時間"
                  @update-time="updateTime"
                ></TimePicker>
              </div>
              <div class="item-tool">
                <div
                  class="cta del"
                  @click="rmShippingOption"
                  :data-index="index"
                ></div>
              </div>
            </div>
            <div class="item-botm">
              <div class="input-wrap">
                <input
                  type="text"
                  placeholder="請輸入取貨地址"
                  v-model="shippingOption.location"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="cta-additem" @click.prevent="addShippingOption">
          新增取貨時間地址
        </div>
      </div>
    </div>
    <div class="content-block">
      <h2 class="lg-title">
        <div class="title-word">付款方式</div>
      </h2>
      <div
        v-for="(paymentMethod, index) in settings.paymentMethods"
        :key="index"
        class="switch-block"
      >
        <template
          v-if="
            (paymentMethod.name === 'ECPay' && isCompanyMember) ||
              availablePaymentMethods.includes(paymentMethod.name)
          "
        >
          <div class="s-title">{{ paymentMethod.description }}</div>
          <div class="switcher-wrap">
            <input
              class="switcher"
              :id="`method0${index}`"
              type="checkbox"
              :value="paymentMethod.value"
              :checked="paymentMethod.active === true"
              v-model="paymentMethod.active"
            />
            <label
              :for="`method0${index}`"
              v-if="availablePaymentMethods.length > 1"
            ></label>
          </div>
        </template>
      </div>
    </div>
    <div class="content-block" v-if="isCompanyMember">
      <h2 class="lg-title">
        <div class="title-word">綠界付款資訊</div>
      </h2>
      <div class="input-block">
        <div class="input-wrap">
          <input
            type="text"
            placeholder="Merchant ID"
            v-model="ecpay.merchantId"
          />
        </div>
        <div class="input-wrap">
          <input type="text" placeholder="HashKey" v-model="ecpay.hashKey" />
        </div>
        <div class="input-wrap">
          <input type="text" placeholder="HashIV" v-model="ecpay.hashIV" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, debounce } from 'lodash';
import memberSettingsMixin from '@/mixins/member-settings';
import deliveryTypes from '@/utils/delivery-types';
import paymentMethods from '@/utils/payment-methods';
import defaultSetting from '@/utils/default-create-group-setting';
import { mapGetters } from 'vuex';
import { updateECPay, getECPay } from '@/api/member';

export default {
  mixins: [memberSettingsMixin],
  data() {
    return {
      key: 'default_init_group_buying_settings',
      deliveryTypes: deliveryTypes, //系統可用寄送方式
      paymentMethods: paymentMethods, //系統可用付款方式選項
      settings: defaultSetting, //預設設定
      ecpay: {
        merchantId: '',
        hashIV: '',
        hashKey: ''
      }
    };
  },
  computed: {
    ...mapGetters(['isCompanyMember']),
    availablePaymentMethods() {
      let paymentMethods = [];
      switch (this.settings?.deliveryType) {
        //面交
        case 1:
          paymentMethods = ['Personally', 'Transfer', 'ECPay'];
          break;
        //寄送
        case 2:
          paymentMethods = ['Transfer', 'Cash_on_delivery', 'ECPay'];
          break;
      }

      if (this.isCompanyMember === false) {
        var ecpayIndex = paymentMethods.findIndex(pname => pname === 'ECPay');

        if (ecpayIndex !== -1) {
          paymentMethods.splice(ecpayIndex, 1);
        }
      }

      return paymentMethods;
    }
  },
  methods: {
    getDataSuccessCallback(data) {
      this.settings = data;

      //更新payment methods
      if (this.settings?.paymentMethods) {
        this.paymentMethods = this.paymentMethods.map(ele => {
          let paymentMethod = this.settings.paymentMethods.find(
            p => p?.name == ele.name
          );

          if (paymentMethod) {
            ele.active = paymentMethod?.active === true;
          }

          return ele;
        });

        this.settings.paymentMethods = this.paymentMethods;
      }
    },
    //取得預設面交選項
    defaultShippingOption() {
      return {
        time_from: '',
        time_to: '',
        location: ''
      };
    },
    //新增面交選項
    addShippingOption() {
      let shippingOptions = get(this.settings, 'shippingOptions', []);
      shippingOptions.push(this.defaultShippingOption());
      this.$set(this.settings, 'shippingOptions', shippingOptions);
    },
    //移除面交選項
    rmShippingOption(e) {
      let index = e.currentTarget.dataset.index;
      this.settings.shippingOptions.splice(index, 1);
    },
    updateTime(time, option) {
      this.$set(this.settings.shippingOptions[option.index], option.key, time);
    },
    getECPay() {
      getECPay().then(data => {
        this.ecpay.merchantId = data?.merchant_id;
        this.ecpay.hashKey = data?.hash_key;
        this.ecpay.hashIV = data?.hash_iv;
      });
    }
  },
  watch: {
    'settings.deliveryType': function(newVal, oldVal) {
      if (newVal === 2) {
        this.settings.paymentMethods.forEach((ele, index) => {
          if (ele.name === 'Personally') {
            this.settings.paymentMethods[index].active = false;
          }

          if (ele.name === 'Transfer') {
            this.settings.paymentMethods[index].active = true;
          }
        });
      }
    },
    settings: {
      handler: debounce(function(newVal, oldVal) {
        if (this.noSettingYet) {
          this.settings = defaultSetting;
          return;
        }

        if (oldVal !== defaultSetting) {
          this.updateSettings();
        }
      }, 500),
      deep: true
    },
    ecpay: {
      handler: debounce(function(newVal, oldVal) {
        updateECPay(newVal);
      }, 500),
      deep: true
    },
    isCompanyMember(newVal) {
      if (newVal === true) {
        console.log('企業會員');
        this.getECPay();
      }
    }
  },
  mounted() {
    if (this.isCompanyMember === true) {
      console.log('企業會員');
      this.getECPay();
    }
  }
};
</script>
